export const DataConstant = {
    status: {
        Success: "Success",
        Failure: "Failure"
    },
    defaultRepId:"1",
    httpMethod:{
        get:"get",
        post:"post"
    },
    base64:'base-64',
    RepID:'{{RepID}}'
}