import React, { useEffect, useState } from "react";
import { ReactPageGenerator } from "../utilities/PageBuilderUtility/BaseComponent/index";
import { NotFound } from "./not-found";
import { useLang, useCountry } from "@cloudmlm/cms-page-components";
import { NotificationManager } from "react-notifications";
import { DataConstant } from "../constants";
import { useHistory } from "react-router-dom";

const API_URL = process.env.REACT_APP_API_URL;

export const PageGenerator = (props) => {
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState(null);
  const [pageId, setPageId] = useState(null);
  const [isFooter, setFooter] = useState(false);
  const history = useHistory();

  const LanguageCode = useLang();
  const CountryCode = useCountry();

  const GetPageSchema = () => {
    fetch(
      `${API_URL}/PageConfiguration/GetPageSchema?slug=${props.slug}&countryCode=${CountryCode}&languageCode=${LanguageCode}`,
      {
        method: "get",
        headers: {
          "content-type": "application/json; charset=UTF-8",
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then(async (responseJson) => {
        if (
          responseJson &&
          responseJson.status === DataConstant.status.Success &&
          responseJson.data
        ) {
          const task_data = JSON.parse(responseJson.data.content).task_data;
          await setFormData(task_data);
          await setPageId(responseJson.id);
        } else {
          setError("Data not found");
        }
      })
      .catch((err) => {
        setError(err);
      });
  };

  const onFormSubmit = (formResponse, formSchema, formId) => {
    document.getElementsByName(formResponse[0].name)[0].closest("form").id =
      formId;
    let formElement = {};

    formResponse.forEach(function (e) {
      const resultElement = formSchema.find((x) => x.field_name === e.name);
      let checkBoxOption = "";
      let resultElementValue = e.value;

      resultElement.label = resultElement.label.replace(/ \s*$/, "");

      if (resultElement.element === "RadioButtons") {
        resultElementValue =
          resultElementValue.length > 0
            ? resultElement.options.find((x) => x.key === resultElementValue[0])
                .text
            : null;
      }
      if (resultElement.element === "Checkboxes") {
        const Checkbox = formSchema.find((x) => x.field_name === e.name);
        resultElementValue.forEach(function (value) {
          checkBoxOption =
            checkBoxOption +
            Checkbox.options.find((x) => x.key === value).text +
            ",";
        });
        resultElementValue = checkBoxOption.replace(/,\s*$/, "");
      }
      formElement[resultElement.label] = resultElementValue;
    });

    const responseModel = {
      Source: pageId,
      formResponseJson: JSON.stringify(formResponse),
      tableResponseJson: JSON.stringify(formElement),
      formId: formId,
      Status: true,
    };

    let responsedata;

    fetch(`${API_URL}/FormReponse/SaveFormResponse`, {
      method: "POST",
      headers: {
        "content-type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify(responseModel),
    })
      .then((response) => {
        responsedata = response;
        return response.json();
      })
      .then((responseJson) => {
        if (responsedata.status !== 200) {
          NotificationManager.error(responseJson.title, "Form Response", 5000);
        } else {
          document.getElementById(formId).reset();
          NotificationManager.success(
            "Form response saved successfully",
            "Form Response",
            5000
          );
        }
      })
      .catch((err) => {
        NotificationManager.error(err, "Form Response", 5000);
      });
  };

  useEffect(() => {
    async function calledMethod() {
      await GetPageSchema();
    }

    calledMethod();
  }, []);

  useEffect(() => {
    if (!isFooter) {
      //GetPageSchema();
      if (
        document.querySelector(".cm-footer-form") &&
        document
          .querySelector(".cm-footer-form")
          .querySelector("input[type='text']") &&
        document
          .querySelector(".cm-footer-form")
          .querySelector("input[type='submit']")
      ) {
        setTimeout(() => {
          document
            .querySelector(".cm-footer-form")
            .querySelector("input[type='submit']").value = "send";
        }, 3000);
        document
          .querySelector(".cm-footer-form")
          .querySelector("input[type='text']")
          .setAttribute("placeholder", "Your Email");
        document
          .querySelector(".cm-footer-form")
          .querySelector("input[type='text']")
          .setAttribute("class", "form-control ghost");
        setFooter(true);
      }
    }
  }, [formData, isFooter]);

  return (
    <>
      {error &&
        history.location.pathname !== "/videos/bio" &&
        history.location.pathname !== "/videos/products" && <NotFound />}
      {formData && (
        <div className="frontend-preview">
          <ReactPageGenerator
            data={formData}
            onFormSubmit={onFormSubmit}
            repId={props.repId}
            repName={props.repName}
            isRepName={props.isRepName}
            isReplication={props.isReplication}
          />
        </div>
      )}
    </>
  );
};
