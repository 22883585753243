import React, { useEffect, useState } from "react";

const PolicyDocument = (props) => {
  const [content, setContent] = useState("");

  useEffect(() => {
    fetch(props.url)
      .then((result) => result.json())
      .then((response) => {
        if (response.success) setContent(response.content);
        else setContent("Unable to load document");
      });
  }, []);

  return (
    <div
      className="policy-document"
      style={{ maxWidth: "900px", margin: "0 auto" }}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  );
};

export default PolicyDocument;
