import React from "react";

import  PageElements  from "@cloudmlm/cms-page-components";

const Footer = PageElements["Footer"];

const HardFooter = (props) => {
  return (
    <Footer
      mutable={true}
      data={{
        id: "4A968AA2-498B-4544-8A90-DDE4C51EC96C",
        element: "Footer",
        text: "Footer",
        required: false,
        field_name: "footerD4B56149-1683-4D5C-9894-14510D9F4F72",
        footeArea1:"",
        dirty: false,
        footeArea2: "",
        footeArea3:"",
        formShortCode:"",
        bottomText: '<p>Copyright © 2022 Infinite Vizion. All Rights Reserved.</p>',
      }}
      onFormSubmit={props.onFormSubmit}
      repId={props.repId}
      repName={props.repName}
      isRepName={props.isRepName}
      isReplication={props.isReplication}
    />
  );
};

export default HardFooter;
