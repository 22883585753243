import React from "react";

const Video = ({ vimeoId, title }) => {
  return (
    <>
      <h2>
        <a href="#">{title}</a>
      </h2>
      <div
        style={{
          padding: "56.25% 0 0 0",
          position: "relative",
        }}
      >
        <iframe
          src={`https://player.vimeo.com/video/${vimeoId}?color=b26b53&amp;byline=0&amp;portrait=0`}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen=""
        />
      </div>
    </>
  );
};

export default Video;
