import React, { useState, useEffect } from "react";
import { Route, Link } from "react-router-dom";
import { PageGenerator } from "./components/page-generator";
import { NotFound } from "./components/not-found";
import {useLang,useCountry} from "@cloudmlm/cms-page-components";
import PageElements from '@cloudmlm/cms-page-components'
import { RegexConstant, DataConstant } from "./constants";
import ShareAndSaveBanner from "./components/ShareAndSaveBanner";
import PolicyDocument from "./components/PolicyDocument";
import ProductVideos from "./components/videos/ProductVideos";
import BioVideos from "./components/videos/BioVideos";
//import Header from './header'
const { Header } = PageElements;

const API_URL = process.env.REACT_APP_API_URL;

// let repID =DataConstant.defaultRepId;

let companyDetails = {
  isCompanyDetailsDisplay: false,
  CompanyName: "",
};

let helpURL = process.env.REACT_APP_SHOP_HELPURL;

export const Routes = () => {
  const [menuList, setMenuList] = useState(null);
  const [deafultRoute, setDeafultRoute] = useState(null);
  const [error, setError] = useState(null);
  const [repID, setRepId] = useState(DataConstant.defaultRepId);
  const [repName, setRepName] = useState();
  const [isReplication, setIsReplication] = useState(false);
  const [isRepName, setIsRepName] = useState(false);
  const LanguageCode = useLang();
  const CountryCode = useCountry();

  const [loginURL, setloginURL] = useState(process.env.REACT_APP_SHOP_LOGINURL);
  const [signupURL, setsignupURL] = useState(
    process.env.REACT_APP_SHOP_SIGNUPURL
  );
  const [cartURL, setcartURL] = useState(process.env.REACT_APP_SHOP_CARTURL);

  const AuthenticateRep = async (subDomain) => {
    fetch(`${process.env.REACT_APP_REPLICATION_ENDPOINT}/${subDomain}`, {
      method: "get",
      headers: {
        "content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then(async (resJson) => {
        if (
          resJson &&
          resJson.Status === DataConstant.status.Success &&
          resJson.Value
        ) {
          await setRepId(parseInt(resJson.Value.RepId));
          await setRepName(resJson.Value.SiteName);
          if (subDomain !== null && resJson.Value.SiteName !== null) {
            if (
              subDomain.toLowerCase() === resJson.Value.SiteName.toLowerCase()
            ) {
              await setIsRepName(true);
            }
          }
          if (resJson.Value.RepId !== null) {
            await setIsReplication(true);
          }
          companyDetails.isCompanyDetailsDisplay = true;
          if (
            typeof resJson.Value.Company !== "undefined" &&
            resJson.Value.Company !== null
          )
            companyDetails.CompanyName = resJson.data.company;
          else if (
            resJson.Value.FirstName !== null &&
            resJson.Value.LastName !== null
          )
            companyDetails.CompanyName = `${
              resJson.Value.FirstName
            } ${resJson.Value.LastName.charAt(0).toUpperCase()}.`;
          else companyDetails.CompanyName = "";

          await setloginURL(
            loginURL.replace(DataConstant.RepID, resJson.Value.RepId)
          );
          await setsignupURL(
            signupURL.replace(DataConstant.RepID, resJson.Value.RepId)
          );
          await setcartURL(
            cartURL.replace(DataConstant.RepID, resJson.Value.RepId)
          );
        } else {
          const { host, protocol } = window.location;
          window.location.href = `${protocol}//${host.replace(
            /^[^.]+\./g,
            ""
          )}`;
        }
      })
      .catch((error) => console.error(error));
  };

  const GetMenuList = async () => {
    fetch(
      `${API_URL}/Menu/ListOfAllMenus?countryCode=${CountryCode}&languageCode=${LanguageCode}`,
      {
        method: "get",
        headers: {
          "content-type": "application/json; charset=UTF-8",
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then(async (responseJson) => {
        if (
          responseJson &&
          responseJson.status === DataConstant.status.Success &&
          responseJson.data
        ) {
          const menuList = responseJson.data;
          await setMenuList(menuList);
        } else {
          setError("Data not found");
        }
      })
      .catch((err) => {
        setError(err);
      });
  };

  const GetHomePageDetail = () => {
    fetch(
      `${API_URL}/PageConfiguration/HomePageDetail?countryCode=${CountryCode}&languageCode=${LanguageCode}`,
      {
        method: "get",
        headers: {
          "content-type": "application/json; charset=UTF-8",
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((responseJson) => {
        if (
          responseJson &&
          responseJson.status === DataConstant.status.Success &&
          responseJson.data
        ) {
          const deafultRoute = responseJson.data;
          setDeafultRoute(deafultRoute);
        }
      })
      .catch((err) => {
        setError(err);
      });
  };

  useEffect(() => {
    async function getDetails() {
      const { hostname } = window.location;
      const subDomain = RegexConstant.subDomainRegex.exec(hostname);

      if (subDomain) {
        await AuthenticateRep(subDomain[1]);
      } else {
        await setloginURL(
          loginURL.replace(DataConstant.RepID, DataConstant.defaultRepId)
        );
        await setsignupURL(
          signupURL.replace(DataConstant.RepID, DataConstant.defaultRepId)
        );
        await setcartURL(
          cartURL.replace(DataConstant.RepID, DataConstant.defaultRepId)
        );
      }
      await GetMenuList();
      await GetHomePageDetail();
      // let pageMenuObject = _.find(menuList,function getPageName(obj){return obj.url===window.location.pathname.slice(1,window.location.pathname.length)})
    }
    getDetails();
  }, []);

  const generateRoutes = () => {
    const menuData = ([] = menuList);

    const generateRoute = (item) => {
      const { url, id, isExternalURL } = item;
      if (url && isExternalURL) {
        let ReplacedURL = url.replace(DataConstant.RepID, repID);
        return (
          <>
            {url && (
              <Route
                path={`${url}`}
                key={id}
                exact
                render={() => (window.location.href = ReplacedURL)}
              />
            )}
          </>
        );
      } else {
        return (
          <>
            {url && (
              <Route
                path={`/${url}`}
                key={id}
                exact
                render={(routeProps) => (
                  <PageGenerator
                    {...routeProps}
                    slug={url}
                    repId={repID}
                    repName={repName}
                    isRepName={isRepName}
                    isReplication={isReplication}
                  />
                )}
              />
            )}
          </>
        );
      }
    };

    const generateSubRoute = (items) =>
      items.map((menuItem) => {
        if (menuItem.nodes && menuItem.nodes.length > 0) {
          if (menuItem.isExternalURL) {
            let ReplacedURL = menuItem.url.replace(DataConstant.RepID, repID);
            return (
              <>
                {menuItem.url && (
                  <Route
                    path={`${menuItem.url}`}
                    key={menuItem.id}
                    exact
                    render={() => (window.location.href = ReplacedURL)}
                  />
                )}
                {generateSubRoute(menuItem.nodes)}
              </>
            );
          } else {
            return (
              <>
                {menuItem.url && (
                  <Route
                    path={`/${menuItem.url}`}
                    key={menuItem.id}
                    exact
                    render={(routeProps) => (
                      <PageGenerator
                        {...routeProps}
                        slug={menuItem.url}
                        repId={repID}
                        repName={repName}
                        isRepName={isRepName}
                        isReplication={isReplication}
                      />
                    )}
                  />
                )}
                {generateSubRoute(menuItem.nodes)}
              </>
            );
          }
        }
        return generateRoute(menuItem);
      });

    return menuData.map((menuItem) => {
      if (menuItem.nodes && menuItem.nodes.length > 0) {
        if (menuItem.isExternalURL) {
          let ReplacedURL = menuItem.url.replace(DataConstant.RepID, repID);
          return (
            <>
              {menuItem.url && (
                <Route
                  path={`${menuItem.url}`}
                  key={menuItem.id}
                  exact
                  render={() => (window.location.href = ReplacedURL)}
                />
              )}
              {generateSubRoute(menuItem.nodes)}
            </>
          );
        } else {
          return (
            <>
              {menuItem.url && (
                <Route
                  path={`/${menuItem.url}`}
                  key={menuItem.id}
                  exact
                  render={(routeProps) => (
                    <PageGenerator
                      {...routeProps}
                      slug={menuItem.url}
                      repId={repID}
                      repName={repName}
                      isRepName={isRepName}
                      isReplication={isReplication}
                    />
                  )}
                />
              )}
              {generateSubRoute(menuItem.nodes)}
            </>
          );
        }
      }
      return generateRoute(menuItem);
    });
  };

  return (
    <>
      {/*<ShareAndSaveBanner repID={repID} />*/}
      <Header
        menuList={menuList}
        logo="https://shop.viziondemo.com/resources/images/infinite_vizion_logo.png"
        Link={Link}
        companyDetails={companyDetails}
        loginURL={loginURL}
        signupURL={signupURL}
        cartURL={cartURL}
        repID={repID}
        helpURL={helpURL}
      />
      <Route path={"/videos/products"}>
        <ProductVideos
          repId={repID}
          repName={repName}
          isRepName={isRepName}
          isReplication={isReplication}
        />
      </Route>
      <Route path={"/videos/bio"}>
        <BioVideos
          repId={repID}
          repName={repName}
          isRepName={isRepName}
          isReplication={isReplication}
        />
      </Route>
      <Route path={"/terms-and-conditions"}>
        <PolicyDocument url="#" />
      </Route>
      <Route path={"/privacy-policy"}>
        <PolicyDocument url="#" />
      </Route>
      <Route path={"/cookie-policy"}>
        <PolicyDocument url="#" />
      </Route>
      {menuList && menuList.length > 0 && generateRoutes()}
      {menuList && menuList.length && deafultRoute && (
        <Route
          path={`/`}
          exact
          render={(routeProps) => (
            <PageGenerator
              {...routeProps}
              slug={deafultRoute.slug}
              repId={repID}
              repName={repName}
              isRepName={isRepName}
              isReplication={isReplication}
            />
          )}
        />
      )}
      {(error || (menuList && menuList.length === 0)) && (
        <Route component={NotFound} />
      )}
    </>
  );
};
