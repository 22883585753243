import React from "react";
import HardFooter from "../HardFooter";
import Video from "./Video";

const BioVideos = (props) => {
  return (
    <div>
      <section className="component text-center">
        <h1 className="video-title">Bio Videos</h1>
      </section>
      <div className="component video-list">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-md-6">
              <Video title="bio september teaser" vimeoId={613978475} />
            </div>
            <div className="col-12 col-md-6">
              <Video title="bio february teaser" vimeoId={515992016} />
            </div>
            <div className="col-12 col-md-6">
              <Video title="the story behind infinite vizion" vimeoId={409144232} />
            </div>
            <div className="col-12 col-md-6">
              <Video title="rapidCT" vimeoId={531112028} />
            </div>
          </div>
        </div>
      </div>
      <HardFooter {...props} />
    </div>
  );
};

export default BioVideos;
