import React from "react";
import Video from "./Video";
import HardFooter from "../HardFooter";

const ProductVideos = (props) => {
  return (
    <div>
      <section className="component text-center">
        <h1 className="video-title">Product Videos</h1>
      </section>
      <div className="component video-list">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-md-6">
              <Video title="hydrate" vimeoId={515931463} />
            </div>
            <div className="col-12 col-md-6">
              <Video title="balance" vimeoId={515930722} />
            </div>
            <div className="col-12 col-md-6">
              <Video title="acv biotics" vimeoId={613721233} />
            </div>
            <div className="col-12 col-md-6">
              <Video title="limitless" vimeoId={515929477} />
            </div>
            <div className="col-12 col-md-6">
              <Video title="defense" vimeoId={515933782} />
            </div>
            <div className="col-12 col-md-6">
              <Video title="sleep" vimeoId={515925289} />
            </div>
            <div className="col-12 col-md-6">
              <Video title="gh almond protein" vimeoId={515933179} />
            </div>
            <div className="col-12 col-md-6">
              <Video title="gh protein" vimeoId={515932672} />
            </div>
            <div className="col-12 col-md-6">
              <Video title="vital iq" vimeoId={515922564} />
            </div>
            <div className="col-12 col-md-6">
              <Video title="gummies" vimeoId={515932194} />
            </div>
            <div className="col-12 col-md-6">
              <Video title="daily" vimeoId={515936886} />
            </div>
            <div className="col-12 col-md-6">
              <Video title="the relief line" vimeoId={515925813} />
            </div>
            <div className="col-12 col-md-6">
              <Video title="the day serum" vimeoId={515934574} />
            </div>
            <div className="col-12 col-md-6">
              <Video title="the night serum" vimeoId={515928444} />
            </div>
            <div className="col-12 col-md-6">
              <Video title="lean" vimeoId={515929933} />
            </div>
            <div className="col-12 col-md-6">
              <Video title="pure curcumin" vimeoId={515926614} />
            </div>
            <div className="col-12 col-md-6">
              <Video title="pure cbd" vimeoId={515927249} />
            </div>
            <div className="col-12 col-md-6">
              <Video title="pet" vimeoId={515927923} />
            </div>
          </div>
        </div>
      </div>
      <HardFooter {...props} />
    </div>
  );
};

export default ProductVideos;
