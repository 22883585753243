import React from "react";
import  PageElements  from "@cloudmlm/cms-page-components";
import HardFooter from "../../../components/HardFooter";

export const ReactPageGenerator = (props) => {
  const getSimpleElement = (item) => {
    const Element = PageElements[item.element];
    return (
      <Element
        mutable={true}
        key={`page_${item.id}`}
        data={item}
        onFormSubmit={props.onFormSubmit}
        repId={props.repId}
        repName={props.repName}
        isRepName={props.isRepName}
        isReplication={props.isReplication}
      />
    );
  };
  let data_items = props.data;

  /**
   * 0. TEMPORARY FORCED FOOTER REMOVAL GUIDE
   * 1. Remove the "Footer" constant defined at the top of this script
   * 2. Remove the "Footer" element defined within the return statement below
   * 3. Remove the second conditional clause from the if statement right below this comment block
   *
   * After the above steps have been completed, the footer will render based on the page components.
   */
  const items = data_items.map((item) => {
    if (!item || item.element === "Footer") return null;
    return getSimpleElement(item);
  });

  return (
    <>
      {items}
      <HardFooter {...props} />
    </>
  );
};
