import React, { useEffect } from "react";
import "./App.css";
// import "@cloudmlm/cms-page-components/ComponentStyles.css";
import { BrowserRouter } from "react-router-dom";
import { Routes } from "./Route";
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import { useCountry, useLang } from "@cloudmlm/cms-page-components";

function App() {
  const country = useCountry();
  const language = useLang();

  useEffect(() => {
    const domainParts = window.location.host.split(".");
    const domainEnd = domainParts[domainParts.length - 1].toUpperCase();
    if (domainEnd !== "CA" || (domainEnd === "CA" && country === "CA")) return;

    console.debug("Enforcing canada country selection");
    localStorage.setItem(
      "selectedCountryLanguageConfig",
      JSON.stringify({
        selectedLang: language ?? "en",
        selectedCountry: "CA",
      })
    );
    window.location.reload();
  }, [country, language]);

  return (
    <React.Suspense fallback={<div className="loader" />}>
      <NotificationContainer />
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </React.Suspense>
  );
}

export default App;
