export const NotFound = () => {
  return (
    <>
      <div className="page-not-found">
        <div>
          <h1>404</h1>
          <h3>page not found</h3>
          <p>
            Sorry, but the page you are looking for is not found. Please, make
            sure you have typed the currect URL.
          </p>
          <button
            className="ghost-btn"
            onClick={(e) => {
              window.location.href = "/";
            }}
          >
            GO TO HOMEPAGE
          </button>
        </div>
      </div>
    </>
  );
};
